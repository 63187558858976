import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Loading from 'components/Loading';
import Footer from 'components/Footer';
import Header from 'components/Header';
import { useGlobalState } from 'components/GlobalVariables/States';
import { setGlobalState } from 'components/GlobalVariables/States';

export default function Layout({ headerVariant }) {
  const [isInit] = useGlobalState('isInit');

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setGlobalState('isInit', true);         
    },2500);
  }, []);

  return (
    <>
      {isInit ?
        <>
          <Header variant={headerVariant} />
          <Outlet />
          <Footer />
        </>
      :
        <Loading />
      }
    </>
  );
}
