import React from 'react'
import Div from '../Div'

export default function TextWidget({logoSrc=null, title=null, logoAlt, text}) {
  return (
    <Div className="cs-text_widget">
      {title ? <h2 className="cs-widget_title">{title}</h2> : null}
      {logoSrc ? <img src={logoSrc} alt={logoAlt} />: null}
      <p>{text}</p>
    </Div>
  )
}
