import React from 'react'
import Div from '../Div'
import './logolist.scss'
import { Link } from 'react-router-dom'

export default function LogoList() {
  const partnerLogos = [
    {
      href: '',
      src: '/images/partners/partner_1.svg',
      name: 'Muons',
      alt:'Partner'
    },
    {
      href: 'https://www.alpaelectric.com/',
      src: '/images/partners/partner_2.svg',
      name: 'Alpa Electric',
      alt:'Partner'
    },
    {
      href: 'https://www.alpaelectric.com/alpaway',
      src: '/images/partners/partner_3.svg', 
      name: 'Alpa Way',
      alt:'Partner'
    }
  ]
  return (
    <Div className="cs-partner_logo_wrap">
      {partnerLogos.map((partnerLogo, index)=>
      <Link target={"_blank"} to={partnerLogo.href}>
        <div className="cs-partner_logo" key={index}>
          <img src={partnerLogo.src} alt={partnerLogo.alt} />
          <div className="cs-partner_logo" style={{textAlign: 'center'}}>
            {partnerLogo.name}
          </div>
        </div>
      </Link>
      )}
    </Div>
  )
}
