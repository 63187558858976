import { Icon } from '@iconify/react';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { pageTitle } from '../../helper';
import PageHeading from '../PageHeading';
import Portfolio from '../Portfolio';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Hero5 from 'components/Hero/Hero5';

export default function ProductsPage() {
  pageTitle('Epigeniun - Products');
  const [active, setActive] = useState('all');
  const [itemShow, setItemShow] = useState(7);
  const portfolioData = [
    {
      title: 'GeOrb',
      subtitle: 'See Details',
      href: '/portfolio/portfolio-details',
      src: '/images/products/georb/GeOrb_portrait.png',
      category: 'enterprise',
    },
    {
      title: 'AW System',
      subtitle: 'See Details',
      href: '/portfolio/portfolio-details',
      src: '/images/products/awsystem/AwSystem_portrait.png',
      category: 'industrial',
    },
    {
      title: 'Sentinel',
      subtitle: 'See Details',
      href: '/portfolio/portfolio-details',
      src: '/images/products/sentinel/Sentinel_portrait.jpg',
      category: 'electronic_ai',
    },
    {
      title: 'Smart Grower',
      subtitle: 'See Details',
      href: '/portfolio/portfolio-details',
      src: '/images/products/smartgrower/SmartGrower_portrait.jpg',
      category: 'personal',
    },
  ];
  const categoryMenu = [
    {
      title: 'Industrial',
      category: 'industrial',
    },
    {
      title: 'Enterprise',
      category: 'enterprise',
    },
    {
      title: 'Electronic + AI',
      category: 'electronic_ai',
    },
    {
      title: 'Personal',
      category: 'personal',
    },    
  ];

  const heroSocialLinks = [
    {
      name: 'Behance',
      links: '/',
    },
    {
      name: 'Twitter',
      links: '/',
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      {/* Start Hero Section */}
      <Hero5
        title="Grow Your Business<br /> With Digital Strategy"
        subtitle="We deliver best problem solving solution for our client and provide finest finishing product in present and upcoming future."
        btnLink="contact"
        btnText="Let’s talk"
        socialLinksHeading="Follow Us"
        heroSocialLinks={heroSocialLinks}
      />
      {/* End Hero Section */}
      <Spacing lg="145" md="80" />
      <Div className="container">
        <Div className="cs-portfolio_1_heading">
          <SectionHeading title="Some recent work" subtitle="Our Portfolio" />
          <Div className="cs-filter_menu cs-style1">
            <ul className="cs-mp0 cs-center">
              <li className={active === 'all' ? 'active' : ''}>
                <span onClick={() => setActive('all')}>All</span>
              </li>
              {categoryMenu.map((item, index) => (
                <li
                  className={active === item.category ? 'active' : ''}
                  key={index}
                >
                  <span onClick={() => setActive(item.category)}>
                    {item.title}
                  </span>
                </li>
              ))}
            </ul>
          </Div>
        </Div>
        <Spacing lg="90" md="45" />
        <Div className="row">
          {portfolioData.slice(0, itemShow).map((item, index) => (
            <Div
              className={`${
                index === 3 || index === 6 ? 'col-lg-8' : 'col-lg-4'
              } ${
                active === 'all'
                  ? ''
                  : !(active === item.category)
                  ? 'd-none'
                  : ''
              }`}
              key={index}
            >
              <Portfolio
                title={item.title}
                subtitle={item.subtitle}
                href={item.href}
                src={item.src}
                variant="cs-style1 cs-type1"
              />
              <Spacing lg="25" md="25" />
            </Div>
          ))}
        </Div>

        <Div className="text-center">
          {portfolioData.length <= itemShow ? (
            ''
          ) : (
            <>
              <Spacing lg="65" md="40" />
              <span
                className="cs-text_btn"
                onClick={() => setItemShow(itemShow + 3)}
              >
                <span>Load More</span>
                <Icon icon="bi:arrow-right" />
              </span>
            </>
          )}
        </Div>
      </Div>
      <Spacing lg="145" md="80" />
      <Footer />
    </>
  );
}
