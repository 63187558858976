import { Icon } from '@iconify/react';
import React from 'react';
import Slider from 'react-slick';
import Div from '../Div';
import ServiceDesc from '../ServiceDesc';

export default function ServiceSlider() {
  const timelineData = [
    [
      {
        service: 'Hardware',
        description: 'We design specialized hardware with cutting-edge technology that fits your needs',
        width: '75%',
      },
      {
        service: 'Software',
        description: 'We develop software dedicated to the control and processing of data from devices and embedded systems',
        width: '75%',

      },
    ],
    [
      {
        service: 'Industrial solutions',
        description: 'We design specialized technological solutions to tackle the day-to-day challenges of your industrial sector',
        width: '90%',
      },
      {
        service: 'Web ecosystems',
        description: 'We develop a wide range of web ecosystems and applications that adjust to your needs',
        width: '95%',
      },
    ],
  ];

  /** Slider Settings **/
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="bi:arrow-left" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="bi:arrow-right" />
    </div>
  );
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    arrows: false,
    responsive: [
      {
        breakpoint: 470,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings} className="cs-arrow_style3">
      {timelineData.map((item, index) => (
        <Div key={index}>
          <ServiceDesc columnData={item} />
        </Div>
      ))}
    </Slider>
  );
}
