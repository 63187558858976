import React, { useEffect } from 'react';
import Card from '../Card';
import FunFact from '../FunFact';
import Hero from '../Hero';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Button from '../Button';
import LogoList from '../LogoList';
import ServiceSlider from '../Slider/ServiceSlider';
import { pageTitle } from '../../helper';

export default function Home() {
  pageTitle('Epigeniun');

  // Hero Social Links
  const heroSocialLinks = [
    {
      name: 'Linkedin',
      links: '/',
    },
    {
      name: 'X',
      links: '/',
    },
  ];

  // FunFact Data
  const funfaceData = [
    {
      title: 'We are hardware designers',
      factNumber: '1',
    },
    {
      title: 'We are software developers',
      factNumber: '2',
    },
    {
      title: 'Team Members',
      factNumber: '3',
    },
    {
      title: 'Digital products',
      factNumber: '4',
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Start Hero Section */}
      <Hero
        title="A Window to <br/>the Future"
        subtitle="Building a better future hand in hand with science and technology. Let's walk together towards this dream!"
        btnText=""
        btnLink="/contact"
        scrollDownId="#"
        socialLinksHeading="Follow Us"
        heroSocialLinks={heroSocialLinks}
        bgImageUrl="/images/main_cover.jpg"
      />
      {/* End Hero Section */}

      {/* Start FunFact Section */}
      <div className="container">
        <FunFact
          variant="cs-type1"
          title="Our fun fact"
          subtitle=""
          data={funfaceData}
        />
      </div>
      <Spacing lg="150" md="80" />
      {/* End FunFact Section */}

      {/* Start Service Section */}
      <Spacing lg="150" md="80" />
      <Div id="product">
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Products that may interest you"
                subtitle=""
                btnText="See All Products"
                //btnLink="/service"
                btnLink="/"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-8">
              <Div className="row">
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="AW System"
                    link="/service1/service-details"
                    //link="/service/service-details"
                    src="/images/products/awsystem/AwSystem_portrait.png"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="GeOrb"
                    link="/service1/service-details"
                    //link="/service/service-details"
                    src="/images/products/georb/GeOrb_portrait.png"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Sentinel"
                    link="/service1/service-details"
                    //link="/service/service-details"
                    src="/images/products/sentinel/Sentinel_portrait.jpg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Smart Grower"
                    link="/service1/service-details"
                    //link="/service/service-details"
                    src="/images/products/smartgrower/SmartGrower_portrait.jpg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Product Section */}

      {/* Start Services Section */}
      <Spacing lg="150" md="80" />
      <Div className="cs-shape_wrap_2">
        <Div className="cs-shape_2">
          <Div />
        </Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Services we can help you with"
                subtitle="What can we do"
                variant="cs-style1"
              />
              <Spacing lg="45" md="45" />
              <Div>
                <Button btnLink={'/contact'} btnText={'Get a Quote'} />
              </Div>
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <ServiceSlider />
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Services Section */}

      {/* Start Partners LogoList Section */}
      <Spacing lg="100" md="80" />
      <Div className="container">
        <SectionHeading
          title="Our partners"
          subtitle=""
          variant="cs-style1"
        />
        <Spacing lg="15" md="80" />     
        <LogoList />
      </Div>
      <Spacing lg="75" md="80" />
      {/* End Partners LogoList Section */}
    </>
  );
}
