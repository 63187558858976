import React from "react";
import logo from "assets/img/epigeniun1.svg";

function Loading() {
    return (
        <div className="Loading">
            <div className="stage">
                <div className="ping"></div>
            </div>
            <img alt="..." src={logo} />
        </div>
    )
}

export default Loading;