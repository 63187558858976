import React from 'react'
import Div from '../Div'
import './servicedesc.scss'

export default function ServiceDesc({columnData}) {
  return (
    <>
      {columnData.map((item,index)=>(
        <Div className="cs-time_line cs-style1" key={index}>
          <h3 className="cs-accent_color">{item.service}</h3>
          <h2>{item.name}</h2>
          <p style={{width: item.width, textAlign: 'justify'}}>{item.description}</p>
          <p>{item.type}</p>
        </Div>
      ))}
    </>
  )
}
