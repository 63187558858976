import React from 'react'
import Div from '../Div'
import ContactInfoWidget from '../Widget/ContactInfoWidget'
import MenuWidget from '../Widget/MenuWidget'
import SocialWidget from '../Widget/SocialWidget'
import TextWidget from '../Widget/TextWidget'
import './footer.scss'

export default function Footer({copyrightText, logoSrc, logoAlt, text}) {
  const copyrightLinks = [
    {
      title: 'All rights reserved',
      href: '/'
    }
  ]
  
  const serviceMenu = [
    {
      title: 'Industrial Solutions',
      href: '/service/service-details'
    },
    {
      title: 'Software Develop',
      href: '/service/service-details'
    },
    {
      title: 'Hardware Design',
      href: '/service/service-details'
    },
    {
      title: 'Web ecosystems',
      href: '/service/service-details'
    },
  ]

  return (
    <footer className="cs-fooer">
      <Div className="cs-fooer_main">
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-5 col-sm-6">
              <Div className="cs-footer_item">
                <TextWidget 
                  logoSrc='/images/footer_logo.svg' 
                  logoAlt='Logo'
                  text ='We develop cutting-edge technology for industrial and domestic environments. We are convinced that, hand in hand with science and technology, we can take giant steps toward a better future.'
                />
              </Div>
            </Div>
            <Div className="col-lg-2 col-sm-6">
              <Div className="cs-footer_item">
                <MenuWidget menuItems={serviceMenu} menuHeading='Services we offer'/>
              </Div>
            </Div>
            
            <Div className="col-lg-2 col-sm-6">
              <Div className="cs-footer_item">
                <ContactInfoWidget title='Contact With Us'/>
              </Div>
            </Div>
            
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <TextWidget
                  title={'Follow Us'}
                  logoAlt='Logo'
                  text ='Follow us on our social media and stay tuned for our latest updates.'
                />
                <SocialWidget/>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            <Div className="cs-copyright">© Copyright 2023 - 2024 Epigeniun.</Div>
          </Div>
          <Div className="cs-bottom_footer_right">
            <MenuWidget menuItems={copyrightLinks} variant=' cs-style2'/>
          </Div>
        </Div>
      </Div>
    </footer>
  )
}
